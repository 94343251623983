.login-header{
    background-color: #09b14c ;
    color: #1a1a1a;
    justify-content: unset;
    height: 100vh;
}

.login-input-continer{
display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.input-login {
    margin-bottom: 10px;
    padding: 8px;
    background-color: #fff;
    text-align: center;
    font-size: 20px;
    color: #000;
    font-family: rubik;
    width: 100%;
}

.input-login::placeholder{
    color: #1a1a1a;
}

.button-login {
    padding: 10px;
    color: white;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    width: 100%;
    font-size: 22px;
}

.button-login:hover {
    background-color: #fff !important;
    color: #09b14c !important;
}

.login-container{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

footer{
    background: #000000;
    font-size: 22px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: rubik;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

}

