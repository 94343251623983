.form-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
}

input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #fff;

}

input.error {
    border-color: red;
}

.error-message {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.8em;
}



.main-content form{
    margin: auto;
    background-color: #ccc;
    padding: 1rem;
    overflow-y: auto;
    font-family: rubik;
    font-size: 16px;
    color: #1a1a1a;
    display: flex;
    flex-direction: column;
    align-items: self-end;
}

.select-wrapper{

}

.enter-req{
    width: 40% !important;
    max-width: unset;
    margin-top: 1rem;
    margin-top: 1rem !important;
    display: flex !important;
    align-items: center !important;}


.enter-req input,.enter-req button {
    padding: 0.5rem;
    font-size: 16px;
}

.enter-req input{
    width: 86%;
}



.type-enter{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    margin: auto;
}

.react-datepicker-wrapper{
    margin-bottom: unset!important;
}

textarea {
    margin-bottom: 1rem;
    font-family: Rubik12;
    width: 90%;
    text-align: center;
    font-size: 16px;
}

#editCarChx{
    width: 10px;
    height: 10px;
    margin-top: 1rem;
    margin-left: 0.5rem;
}
.type-enter div{
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.select-wrapper{
    width: 90%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.main-content div label{
    margin-bottom: 0.5rem;
}


.main-content div select{
    padding: 0.7rem;
}


@media only screen and (max-width: 600px) {
    .enter-req input,.enter-req button {
        width: 95%;
    }
    .main-content form {
        margin-top: 3rem;
    }
    select{
        width: 100%;
        padding: 0.5rem;
        text-align: center;
        border-radius: 30px;
        font-family: Rubik12;
    }

    .enter-req div{
        width: 100%;
    }
    textarea {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
        border-radius: 30px;
        font-family: Rubik12;
    }
.alert-text{
    margin-top: 3rem;
}
    .card p{
        max-width: 150px;
    }
}