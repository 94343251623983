.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.loading-container p {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 600px) {

    .App-logo {
        height: 50vmin;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .input-login{
        width: 200px !important;
    }
    .button-login{
        width: 215px !important;
    }
    .login-container{
       width: 80% !important;
    }
}
