
.table-list-wrapper {
    font-family: Rubik12 !important;
    margin: auto;
    padding: 1rem;
     background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
     max-width: 100%;
    font-size: 16px;
    color: #1a1a1a;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding-bottom: 0px;
}

.table-list{
    text-align: center;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* could be absolute or relative */

}

tr{
    background-color: #fff;
    padding: 1rem;
}

td{
    padding: 1rem;
}

tr:nth-child(4n), /* Targets 4th, 8th, 12th, ... rows */
tr:nth-child(4n - 1) {
    background-color: #fff; /* White background for every 2 rows */
}

/* Optional: Add styling for odd rows if needed */
tr:nth-child(odd) {
    background-color: #f0f0f0; /* Light gray background for odd rows */
}


table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}


.table-list-wrapper {
    /*width: 100%;*/
}

.table-header {
    width: 100%;
}

.table-body-wrapper {
    overflow-y: auto;
    height: 500px; /* Adjust this height as needed */
}

.table-body {
    width: 100%;
}

th, td{
    width: 110px;
}

th{
    padding: 1rem;
    background-color: #000;
    color: #fff;
}

th:first-of-type{
    padding-right: 30px !important;
}

td{
    text-align: center;
}

.act-btn{
    width: 25px;
    cursor: pointer;
}

.exl-btn{
    width: 35px;
    padding: 1rem;
}

.act-btn:hover{
    opacity: 0.7;
}