.navbar {
    background-color: #000;
    overflow: hidden;
    display: flex; /* Add this to make the navbar flex container */
    flex-direction: column; /* Change to column to arrange links vertically */
    position: fixed; /* To keep it fixed on the side */
    height: 100%; /* Occupy full height */
    direction: rtl; /* Set the direction to right-to-left */
    top: 0;
    right: -100%; /* Start off-screen */
    width: 300px; /* Adjust as needed */
    transition: right 0.3s ease;
    /* Other styling */
    font-size: 22px;
    font-family: Rubik12 !important;
}

.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    margin-left: 0; /* Remove left margin for RTL layout */
    margin-bottom: 10px; /* Add some bottom margin for spacing */
}

.nav-item a {
    display: block;
    color: #FFF;
    text-align: right;
    padding: 14px 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 20px;
    margin-right: 1rem;
}
.nav-logo-item a{
    margin-right: unset !important;
}

.nav-logo-item a:hover{
    background-color: #000 !important;
}

.nav-logo-item a img{
    display: flex;
    align-items: center;
    text-align: center !important;
    margin: auto;
}

.nav-item a:hover {
    background-color: #09b14c;
    /*color: #F8D622;*/
    font-weight: bolder;
}

.navbar.open {
    right: 0; /* Slide in */
}

.hamburger {
    cursor: pointer;
    /* Style as needed */
    position: absolute !important;
    top: 10px;
    right: 20px;
    z-index: 11111;
    font-size: 30px;
    color: #FFF;
    font-weight: bolder;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0ab14c;
    border-radius: 30px;
}

.hamburger span{
    font-size: 22px;
}

.main-content {
    transition: margin-right 0.3s ease;
}

.main-content.shifted {
    margin-right: 300px; /* Match the width of your navbar */
}

.navbar.open {
    right: 0; /* Slide in the navbar */
}

.navbar-logo{
    width: 200px;
    height: 200px;
}

@media only screen and (max-width: 600px) {
    .main-content {
        width: 90%;
    }
}