.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
}

.not-found-text h1 {
    font-size: 7em;
    animation: bounce 0.7s infinite alternate;
}

.not-found-text h2 {
    font-size: 2em;
}

@keyframes bounce {
    to {
        transform: translateY(-30px);
    }
}