.alert-history .card p {
    text-align: right;
}

.alert-text{
    height: 100px;
    width: 80%;
    max-width: 500px;
}

.alert-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content div div h3{
    direction: rtl;
}

.alert-txt-btn{
    display: flex;
    flex-direction: column;
    width: 64%;
    align-items: center;
    background: #ebebeb;
    padding: 1rem;
    max-width: 506px;
}