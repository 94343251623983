@font-face {
    font-family: "rubik12";
    src: url('../fonts/Rubik-Regular.ttf');
}



.logo-unit{
    height: 100px;
    display: none;

}

.alert-mess{
    font-family: rubik12;
    font-size: 16px;
    margin: auto;
    background: #ebebeb;
    margin-top: 10px;
    margin-bottom: 10px;
    /*max-width: 500px;*/
}

.top-mess{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px;
}

.main-content{
    margin: auto;
    margin-bottom: 2rem;
}

.alert-wrapper, .search-wrapper, .cards-wrapper, .car-details-wrapper{
    margin: auto;
    background-color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto;
    /*max-height: 80%;*/
    max-width: 80%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.car-details-wrapper{
    max-height: 350px;
}

/*.cards-wrapper{*/
/*    max-height: 300px;*/
/*}*/

.alert-title{
    font-weight: bold;
}

.alert-cont{
    overflow-wrap: break-word;
    max-width: 250px;
}

body{
    font-family: Rubik12 !important;
    background-color: #ebebeb;
}

.search-car{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-car-input{
    width: 96%;
    max-width: 300px;
    padding: 10px;
    text-align: center;
    font-family: rubik12;
    font-size: 22px;
    color: #1a1a1a;
    background-color: #09b14c;
    margin-bottom: 0px;
}

.search-car-input::placeholder{
    color: #1a1a1a;
    font-weight: bold;
}

.card{
    padding: 10px;
    background: #ebebeb;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.card h3,.card p{
    margin: unset;
    width: 30%;
    text-align: right;
}
.card p:first-of-type-of-type{
    margin: auto;
}

.card p:last-of-type{
    text-align: left;
}



.car-details{
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: right;
}

.car-details h2{
    margin: unset;
    margin-bottom: 1rem;
}

.car-details-1{
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1rem;
    background-color: #ebebeb;
    text-align: right;
}
.bla{
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1rem;
    text-align: right;
    background-color: #ebebeb ;
}


button{
    border-radius: 0px !important;
    color: #fff !important;
    background-color: #000 !important;
    font-family: Rubik12;
    font-weight: bold;
}

button:hover{
    opacity: 0.7;
}

table{
    margin: auto;
}

.page-item.active .page-link {
    background-color: #007bff; /* Example: blue background */
    color: white;
    border-color: #007bff;
}

@media only screen and (max-width: 600px) {
    .alert-wrapper, .search-wrapper, .cards-wrapper, .car-details-wrapper {
   max-height: 600px;
        max-width: 100% !important;
    }
    .alert-wrapper{
        margin-top: 3rem;
    }
    .card{
        flex-direction: column;
        align-items: center;
    }
    .card h3, .card p{
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .card p:last-of-type{
        text-align: center;
    }
}