.car-list-wrapper, .add-car-wrapper {
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    overflow-y: auto;
    /*max-height: 600px;*/
    max-width: 90%;
    font-family: rubik;
    font-size: 16px;
    color: #1a1a1a;
}

    .car-list{
    text-align: center;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.add-car-wrapper input,.add-car-wrapper select {
    /*width: 150px;*/
    /*border-radius: 30px;*/
    text-align: center;
    font-family: rubik;
    font-size: 16px;
    color: #1a1a1a;
    margin-left: 10px;
    margin-right: 10px;
}

.add-car-wrapper div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.add-car-wrapper select {
    padding: 10px;
    width: 90%;
}

.search-car-input{
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 30px;
}

.addcar-btn{
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.carlist-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: auto;
}